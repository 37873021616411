import React from "react";
import { useNavigate } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux'
import { toast } from "react-toastify";
import { addROErrorLog } from "../features/globalFunctions";
import { RegExPattern } from "../features/globalVars";
import myUUid from "../features/myUUid";
import ROIcon from "../components/ROIcon";
import MapItem from "../components/MapItem";
import { useState, useEffect, useMemo } from "react";
import { formatTime } from "../features/formatTime";

const Map = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch()
  // const { cameras } = useSelector((state) => state.cameras)
  // console.log("Mapping component", cameras)

  const [gpsCoords, setGPSCoords] = useState({ lat: 0, long: 0 });

  let liveGPS = true;

  let GPS_History = localStorage.getItem("GPS_History")? JSON.parse(localStorage.getItem("GPS_History")) : [];
  console.log("global var GPS_History", GPS_History)
  useEffect(() => {
    console.log("useEffect start GPS_History ", GPS_History)
    const interval = setInterval(() => {
        GPS_History = localStorage.getItem("GPS_History")? JSON.parse(localStorage.getItem("GPS_History")) : [];
      //console.log('UseEffect Interval modalIsOpen: ', modalIsOpen)
      //console.log('UseEffect Interval user: ', user)
      if (liveGPS) {
        try {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              setGPSCoords({
                lat: position.coords.latitude,
                long: position.coords.longitude,
              });
              // setCameraCoordinates(`${position.coords.latitude}, ${position.coords.longitude}`)
              // let gpsInfo = position
              // localStorage.setItem("GPS_History", `Coordinates: [${position.coords.latitude},${position.coords.longitude}], Timestamp: ${formatTime(position.timestamp)}`)
              // localStorage.setItem("GPS_History", JSON.stringify(position))
              // localStorage.setItem("GPS_Info1", JSON.stringify(position))
              // localStorage.getItem("GPS_History")
              // console.log(localStorage.getItem("GPS_History"))
              // console.log(localStorage.getItem("GPS_Info1"))
              // console.log('gps coords ', gpsCoords)
              // console.log('position ', position)

              GPS_History.push(JSON.stringify(position));
              console.log("the useEffect gpsHistory ", GPS_History);
              localStorage.setItem("GPS_History", JSON.stringify(GPS_History));
            });
          } else {
            console.log("Geolocation is not supported by this browser.");
          }
        } catch (error) {
          console.log("Get geolocation error ", error);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useMemo(() => {
    console.log("the gpsCoords Memo", gpsCoords);
    console.log("the GPS_History Memo", GPS_History);
  }, [gpsCoords, GPS_History]);

  const clearData = () =>{
    console.log("start GPS history ",GPS_History)
    localStorage.setItem('GPS_History', [])
    GPS_History = []
    setGPSCoords({ lat: 0, long: 0 })
    console.log("end GPS history ",GPS_History)
  }

  console.log("return GPS History ", GPS_History)
  return (
    <>
      <section className="pageHead container">
        <h6>Your Map</h6>
        <MapItem latitude={gpsCoords.lat} longitude={gpsCoords.long} />
        <button onClick={() => clearData()}>
            ClearData
        </button>
      </section>
      <div className="pageContent container">
        
        {GPS_History
          ? GPS_History.map((line, index) => {
            // console.log("the line ", line)
              return (
                <>
                  <div className="row">
                    <div className="col-3 text-white"> {index}  TimeStamp: {formatTime(JSON.parse(line).timestamp)}</div>
                    <div className="col-3 text-white"> Coordinates: {JSON.parse(line).coords.latitude}, {JSON.parse(line).coords.longitude}  </div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    </>
  );
};

export default Map;
