import React from "react";
import maplibregl, { Marker, NavigationControl } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef } from "react";

function MapItem({
  MapWrap = "map-wrap",
  MaplibreID = "esrieMap",
  dragMarker = false,
  latitude = 0,
  longitude = 0,
  MarkerCallBack,
  ZoomIn = 18,
  CameraMarker = true,
  CoordinatesInputElement,
  baseMapSelect = true,
}) {
  const refMap = useRef(null);
  const refMapContainer = useRef(null);

  // esrie access token
  const accessToken =
    "AAPTxy8BH1VEsoebNVZXo8HurMrv_42vEbP_M3llu5kdPpda8XkjV9WNBu_kARHWucCIx7F6umd8mLU499dybAVUx0pjlONimoks2e4Cb0tp6IdaD2CMltgPKhjUdyNPb7sMHu1L7GeSbZsCDx4osuq4sE1SOuORa1k4xEnAicUmtYg1PpCRDArTHq6utnnkXdRdRy8qBqsckYr3cvUC3tlIcPVuOYnZWYy2L37tRiVqZRY.AT1_76J3uSWJ";
  // esrie basemap layer
  const basemapEnum = "arcgis/streets";

  useEffect(() => {
    try {
      refMap.current = new maplibregl.Map({
        container: refMapContainer.current,
        style: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/${basemapEnum}?token=${accessToken}`,
        zoom: ZoomIn,
        center: [longitude, latitude]
      });
    } catch (error) {
      console.log("the maps not drawing ", error);
    }
    // console.log("the refmap ", refMap.current)
    if (refMap && refMap.current) {
      refMap.current.addControl(new maplibregl.FullscreenControl());

      let marker = new Marker({ color: "#FF0000", draggable: dragMarker });
      // console.log("the marker bool", dragMarker)
      marker.setLngLat([longitude, latitude]);
      // console.log("the marker", marker)
      marker.addTo(refMap.current);

      let nav = new NavigationControl();
      refMap.current.addControl(nav, "top-right");
    }
  }, [latitude, longitude]);
  return (
    <>
      <div className={MapWrap}>
        <div id={MaplibreID} ref={refMapContainer}></div>
      </div>
    </>
  );
}

export default MapItem;
